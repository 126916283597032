

// import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
// import { getSessionData } from '../utils/session';
// import { BASE_URL } from '../config';
// import { fetchAvailedTime, checkCentralSubscription, checkInitialSubscription } from '../api';

// const TimerContext = createContext();

// export const useTimer = () => {
//   return useContext(TimerContext);
// };

// export const TimerProvider = ({ children }) => {
//   const [localTimer, setLocalTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
//   const [totalTimer, setTotalTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
//   const sessionData = getSessionData();
//   const user_name = sessionData?.username;
//   const email_id = sessionData?.email;

//   const timerIntervalRef = useRef(null);
//   const screenTimeUpdateIntervalRef = useRef(null);
//   const subscriptionCheckIntervalRef = useRef(null);

//   useEffect(() => {
//     const getCurrentDate = () => {
//       const now = new Date();
//       const year = now.getFullYear();
//       const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//       const day = String(now.getDate()).padStart(2, '0'); // Days of the month
//       return `${year}-${month}-${day}`;
//     };

//     const updateScreenTime = async () => {
//       const payload = {
//         user_name,
//         screen_time: 60, // 60 seconds
//         key_date: getCurrentDate(),
//         day_time:60
//       };
//       // console.log(payload);
//       try {
//         const response = await fetch(`${BASE_URL}/api/update_screen_time`, {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify(payload),
//         });
  
//         if (response.ok) {
//           console.log('Screen time updated successfully');
//         } else {
//           console.error('Failed to update screen time');
//         }
//       } catch (error) {
//         console.error('Error updating screen time:', error);
//       }
//     };

//     const startTimer = () => {
//       const storedTimerData = JSON.parse(localStorage.getItem('timerData'));
//       if (!storedTimerData) {
//         setLocalTimer({ hours: 0, minutes: 0, seconds: 0 });
//       } else {
//         const { hours = 0, minutes = 0, seconds = 0 } = storedTimerData;
//         setLocalTimer({ hours, minutes, seconds });
//       }

//       if (!timerIntervalRef.current) {
//         timerIntervalRef.current = setInterval(() => {
//           setLocalTimer(prevTimer => {
//             const newSeconds = prevTimer.seconds >= 59 ? 0 : prevTimer.seconds + 1;
//             const newMinutes = newSeconds === 0 ? prevTimer.minutes + 1 : prevTimer.minutes;
//             const newHours = newMinutes === 60 ? prevTimer.hours + 1 : prevTimer.hours;
//             const updatedTimer = { hours: newHours, minutes: newMinutes % 60, seconds: newSeconds };
//             localStorage.setItem('timerData', JSON.stringify(updatedTimer));
//             return updatedTimer;
//           });
//         }, 6000);
//       }

//       if (!screenTimeUpdateIntervalRef.current) {
//         screenTimeUpdateIntervalRef.current = setInterval(async () => {
//           updateScreenTime();
//           const initialSub = await checkInitialSubscription(user_name);
//           // console.log("initial_access", initialSub);
//         }, 60000);
//       }

//       if (!subscriptionCheckIntervalRef.current) {
//         subscriptionCheckIntervalRef.current = setInterval(async () => {
//           const result = await checkCentralSubscription(user_name, email_id);
//           // console.log(result);
//         }, 90000);
//       }
//     };

//     const fetchTotalTime = async () => {
//       const sessionData = getSessionData(); 
//       const user_name = sessionData?.username;

//       try {
//         const totalSeconds = JSON.parse(sessionStorage.getItem('availed-time'));
//         const hours = Math.floor(totalSeconds / 3600) || 0;
//         const minutes = Math.floor((totalSeconds % 3600) / 60) || 0;
//         const seconds = totalSeconds % 60 || 0;
//         setTotalTime({ hours, minutes, seconds });
//       } catch (error) {
//         console.error('Error fetching total time:', error);
//       }
//     };

//     fetchTotalTime();
//     startTimer();

//     return () => {
//       if (timerIntervalRef.current) {
//         clearInterval(timerIntervalRef.current);
//         timerIntervalRef.current = null;
//       }
//       if (screenTimeUpdateIntervalRef.current) {
//         clearInterval(screenTimeUpdateIntervalRef.current);
//         screenTimeUpdateIntervalRef.current = null;
//       }
//       if (subscriptionCheckIntervalRef.current) {
//         clearInterval(subscriptionCheckIntervalRef.current);
//         subscriptionCheckIntervalRef.current = null;
//       }
//     };
//   }, [user_name, email_id]);

//   return (
//     <TimerContext.Provider value={{ localTimer, totalTimer }}>
//       {children}
//     </TimerContext.Provider>
//   );
// };




import React, { createContext, useState, useEffect, useContext } from 'react';
import { getSessionData } from '../utils/session';
import { BASE_URL } from '../config';
import { fetchAvailedTime, checkCentralSubscription, checkInitialSubscription } from '../api';

const TimerContext = createContext();

export const useTimer = () => {
  return useContext(TimerContext);
};

export const TimerProvider = ({ children }) => {
  const [localTimer, setLocalTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [totalTimer, setTotalTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const sessionData = getSessionData();
  const user_name = sessionData?.username;
  const email_id = sessionData?.email;

  useEffect(() => {
    // Reset the session timer to start at 0:0:0 whenever the app is loaded
    const startTime = Date.now();
    localStorage.setItem('timerStartTime', new Date(startTime).toISOString());
    localStorage.setItem('timerData', JSON.stringify({ hours: 0, minutes: 0, seconds: 0 }));

    const calculateElapsedTime = () => {
      const now = Date.now();
      const elapsedMs = now - startTime;

      const hours = Math.floor(elapsedMs / (1000 * 60 * 60));
      const minutes = Math.floor((elapsedMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((elapsedMs % (1000 * 60)) / 1000);

      setLocalTimer({ hours, minutes, seconds });
    };

    // Update the timer every second
    const timerInterval = setInterval(() => {
      calculateElapsedTime();
    }, 1000);

    const updateScreenTime = async () => {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
      const payload = {
        user_name,
        screen_time: 60, // 60 seconds
        key_date: currentDate,
        day_time: 60,
      };

      try {
        const response = await fetch(`${BASE_URL}/api/update_screen_time`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          console.error('Failed to update screen time');
        }
      } catch (error) {
        console.error('Error updating screen time:', error);
      }
    };

    const screenTimeUpdateInterval = setInterval(updateScreenTime, 60000);

    const subscriptionCheckInterval = setInterval(async () => {
      try {
        const result = await checkCentralSubscription(user_name, email_id);
        console.log('Subscription check result:', result);
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    }, 90000);

    return () => {
      clearInterval(timerInterval);
      clearInterval(screenTimeUpdateInterval);
      clearInterval(subscriptionCheckInterval);
    };
  }, [user_name, email_id]);

  return (
    <TimerContext.Provider value={{ localTimer, totalTimer }}>
      {children}
    </TimerContext.Provider>
  );
};
