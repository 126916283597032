// Function to remove emojis from text
// function removeEmojis(text) {
//     const emojiPattern = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2700}-\u{27BF}\u{24C2}-\u{1F251}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u200d?]/gu;
//     return text.replace(emojiPattern, '.');
//   }

//   export { removeEmojis };


  // function removeEmojis(text) {
  //   // Regex for removing emojis
  //   const emojiPattern = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2700}-\u{27BF}\u{24C2}-\u{1F251}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u200d?]/gu;

  //   // Regex for SSML-unsupported special characters (excluding common punctuation)
  //   const specialCharPattern = /[^a-zA-Z0-9\s.,?!;:'"()$%+-]/g;

  //   // Remove emojis
  //   let cleanedText = text.replace(emojiPattern, '.');

  //   // Remove unsupported special characters
  //   cleanedText = cleanedText.replace(specialCharPattern, '');

  //   return cleanedText;
  // }

  // export { removeEmojis };



  function removeEmojis(text) {
    // Regex for removing emojis
    const emojiPattern = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2700}-\u{27BF}\u{24C2}-\u{1F251}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u200d?]/gu;

    // Remove emojis by replacing them with a dot
    let cleanedText = text.replace(emojiPattern, '.');

    // Replace '&' with "and"
    cleanedText = cleanedText.replace(/&/g, 'and');

    // Remove all other unsupported special characters
    cleanedText = cleanedText.replace(/[^a-zA-Z0-9\s.,?!;:'"()$%+\-]/g, '');

    return cleanedText;
  }

  export { removeEmojis };