import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputBase, IconButton, Popover, Box, Tooltip } from '@mui/material';
import {
  EmojiEmotions as EmojiIcon,
  Send as SendIcon,
  Mic as MicIcon,
  MicNoneOutlined as MicNoneOutlinedIcon,
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
  SettingsInputAntenna as SettingsInputAntennaIcon,
} from '@mui/icons-material';
import Picker from '@emoji-mart/react';
import { useTheme, useMediaQuery } from '@mui/material';
import SubscriptionModal from '../utils/SubscriptionModal';
import * as nsfwjs from 'nsfwjs';

const MessageInput = ({ onSend, isRenderingRef }) => {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [model, setModel] = useState(null);
  const [isAutoAudioEnabled, setIsAutoAudioEnabled] = useState(
    JSON.parse(sessionStorage.getItem('autoAudioEnabled')) || false
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const abortControllerRef = useRef(null);
  const recognitionRef = useRef(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    const loadModel = async () => {
      const loadedModel = await nsfwjs.load();
      setModel(loadedModel);
    };
    loadModel();
  }, []);

  const handleAutoAudioToggle = () => {
    const newState = !isAutoAudioEnabled;
    setIsAutoAudioEnabled(newState);
    sessionStorage.setItem('autoAudioEnabled', JSON.stringify(newState));
  };

  const handleKeyPress = (event) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const initSpeechRecognition = () => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = 'en-US';

      recognition.onstart = () => setIsListening(true);

      recognition.onresult = (event) => {
        let transcript = '';
        for (let i = event.resultIndex; i < event.results.length; i++) {
          transcript += event.results[i][0].transcript;
        }
        setMessage(transcript);
      };

      recognition.onend = () => {
        setIsListening(false);
        recognitionRef.current = null;
      };

      recognitionRef.current = recognition;
    } else {
      console.error('SpeechRecognition is not supported in this browser.');
    }
  };

  const handleMicClick = () => {
    if (isMobile) return;
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    } else {
      initSpeechRecognition();
      recognitionRef.current.start();
    }
  };

  const detectNudity = async (file) => {
    if (!model) {
      console.error('Model not loaded');
      return false;
    }

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = async () => {
          const predictions = await model.classify(img);
          const isNudity = predictions.some(
            (prediction) =>
              (prediction.className === 'Porn' || prediction.className === 'Hentai') &&
              prediction.probability > 0.5
          );
          resolve(isNudity);
        };

        img.onerror = () => reject('Error loading image');
      };

      reader.readAsDataURL(file);
    });
  };

  const handlePhotoChatUpload = async () => {
    const file = inputFileRef.current.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      alert('File size limit exceeded');
      return;
    }

    if (file) {
      const isNudity = await detectNudity(file);
      if (isNudity) {
        alert('The image contains content that is against our policy and will not be uploaded.');
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
      }
    }
  };

///eylon test 3
  const uploadImage = async () => {
    if (!selectedFile) return null;

    console.log("Selected file for upload:", selectedFile);

    setIsUploading(true);

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        const base64data = e.target.result;

        console.log("Base64 data of the file:", base64data);

        const formData = {
          base64data,
          filename: selectedFile.name,
        };

        console.log("Form data being sent:", formData);

        try {
          const response = await fetch('https://t3yb59mz86.l4b.ai/upload', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });

          const data = await response.json();

          console.log("Response from server:", data);

          if (data.success) {
            resolve(data.response);
          } else {
            console.error("Server returned error:", data.error);
            reject(data.error || 'Failed to upload image');
          }
        } catch (error) {
          console.error('Error occurred during upload:', error);
          reject('An error occurred while uploading the file.');
        } finally {
          setIsUploading(false);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject("Failed to read the file.");
      };

      reader.readAsDataURL(selectedFile);
    });
  };

  const handleSend = async () => {
    if (message.trim() || selectedFile) {
      let imageUrl = null;

      if (selectedFile) {
        try {
          imageUrl = await uploadImage();
        } catch (error) {
          alert(error);
          return;
        }
      }

      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
        setIsListening(false);
      }

      onSend({ text: message, imageUrl });

      setMessage('');
      setSelectedFile(null);
    }
  };

  const handleRemoveImage = () => setSelectedFile(null);

  return (
    <div className="text-field">
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box p={2}>
          <Picker
            set="apple"
            onEmojiSelect={(emoji) => {
              const newMessage =
                message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
              setMessage(newMessage);
              setCursorPosition(cursorPosition + emoji.native.length);
            }}
          />
        </Box>
      </Popover>

      <div
        className="input-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '8px',
          borderRadius: '15px',
          backgroundColor: 'white',
          boxSizing: 'border-box',
        }}
      >
        <InputBase
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          multiline
          sx={{
            flex: 1,
            minWidth: 0,
            padding: '6px 0',
            backgroundColor: 'transparent',
            fontSize: '15px',
            lineHeight: 1.5,
            border: 'none',
            outline: 'none',
            overflowWrap: 'break-word',
          }}
        />

        <div
          className="icons-container"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            flexShrink: 0,
          }}
        >
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            onChange={handlePhotoChatUpload}
          />

          <Tooltip title="Attach a file">
            <IconButton onClick={() => inputFileRef.current?.click()}>
              <AttachFileIcon sx={{ color: 'rgb(65, 6, 83)' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Toggle auto audio feature">
            <IconButton onClick={handleAutoAudioToggle} style={{ padding: '8px' }}>
              <SettingsInputAntennaIcon
                sx={{ color: isAutoAudioEnabled ? 'rgb(65, 6, 83)' : 'gray' }}
              />
            </IconButton>
          </Tooltip>

          {!isMobile && (
            <Tooltip title="Start or stop microphone">
              <IconButton onClick={handleMicClick} style={{ padding: '8px' }}>
                {isListening ? (
                  <MicIcon sx={{ color: 'rgb(65, 6, 83)' }} />
                ) : (
                  <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
                )}
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Send message">
            <IconButton onClick={handleSend} disabled={isUploading} style={{ padding: '8px' }}>
              {isUploading ? (
                <CloseIcon sx={{ color: 'gray' }} />
              ) : (
                <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

MessageInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  isRenderingRef: PropTypes.object.isRequired,
};

export default MessageInput;
